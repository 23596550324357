import { ICONS } from "./helper";

export const PAGES = {
  AUTH: {
    LOGIN: {
      id: 1,
      path: "/login",
      name: "Login",
    },
    ROOT_LOGIN: {
      id: 2,
      path: "/",
      name: "Root Login",
    },
  },

  MANAGE_USER: {
    id: 6,
    path: "/users",
    name: "Users",
  },
};

export const MENU_ITEMS = [
  {
    id: 2,
    name: "Users",
    path: PAGES.MANAGE_USER.path,
    icon: ICONS.Users,
  },
];
