import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  styled,
  TextField,
} from "@mui/material";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { PAGES } from "../../../utils/urls";
import { useDispatch } from "react-redux";
import { handleLogin } from "../../../redux/actions/auth";
import { ToastContainer } from "react-toastify";

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: "#12709e",
  fontSize: "34px",
  fontWeight: "300",
  fontFamily: "Comfortaa !important",
}));
const StyledLink = styled(Typography)(({ theme }) => ({
  color: "#12709e",
  fontSize: "18px",
  fontWeight: "300",
  fontFamily: "Comfortaa !important",
}));

const NoScrollbarContainer = styled("div")({
  overflow: "hidden",
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "#e7fb06",
  fontSize: 18,
  marginBottom: 15,
}));

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginValidationSchema = Yup.object({
    userName: Yup.string().required("User name is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLoginSubmit = async (values) => {
    dispatch(handleLogin(values)).then((data) => {
      if (data?.payload?.success) {
        localStorage.setItem("token", data?.payload?.token);
        navigate("/users", { replace: true });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: handleLoginSubmit,
  });

  return (
    <NoScrollbarContainer>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          className="no-scrollbar"
          sx={{
            backgroundImage: "url(assets/images/ips_admin_panel_image.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[100]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
          <Box
            // p={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              padding: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: "64%",
              }}
            >
            </Box>
            <StyledHeading
              variant="h4"
              gutterBottom
            >
              {"Login"}
            </StyledHeading>

            <form onSubmit={formik.handleSubmit}>
              <StyledTextField
                fullWidth
                id="userName"
                name="userName"
                label="User Name"
                variant="outlined"
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                error={
                  formik.touched.userName && Boolean(formik.errors.userName)
                }
                helperText={formik.touched.userName && formik.errors.userName}
              />

              <StyledTextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "16px" }}
              >
                Login
              </Button>

              <Box textAlign="right" p={2}>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
    </NoScrollbarContainer>
  );
};

export default Login;
