import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";

const MenuItems = ({
  item,
  anchorEl,
  open,
  handleClose,
  handleModalOpen,
  handleEditModalOpen,
}) => {

  return (
    <Menu
      id={`basic-menu-${item._id}`}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          marginLeft: "15px",
          boxShadow: Boolean(anchorEl)
            ? "0px 0px 2px rgba(0, 0, 0, 0.1)"
            : "none",
          border: Boolean(anchorEl) ? "1px solid #ccc" : "none",
        },
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => handleModalOpen()}>View</MenuItem>
      <MenuItem onClick={(e) => handleEditModalOpen(e)}>Edit</MenuItem>
    </Menu>
  );
};

export default MenuItems;
