import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Routing from "./routes";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";

const initialToast = {
  hideProgressBar: true,
  closeOnClick: true,
  rtl: false,
  newestOnTop: false,
  draggable: true,
  pauseOnHover: true,
};

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <ToastContainer {...{ initialToast }} />
          <Routing />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
