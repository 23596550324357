import { createSlice } from "@reduxjs/toolkit";
import { getAllUser, getUserByUserId, updateUserById } from "../actions/user";

const initialState = {
  user: {
    success: false,
    loading: false,
    data: [],
    token: null,
    error: {},
  },
  userData: {
    user: {},
    error: "",
    loading: false,
    success: false,
  },
  filterValue: "active",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateFilterValue: (state, action) => {
      return {
        ...state,
        filterValue: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUser.pending, (state, action) => {
        return {
          ...state,
          user: {
            loading: true,
          },
        };
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        return {
          ...state,
          user: {
            loading: false,
            success: true,
            data: action?.payload,
          },
        };
      })
      .addCase(getAllUser.rejected, (state, action) => {
        return {
          ...state,
          user: {
            loading: false,
            success: false,
            data: [],
            error: action?.payload,
          },
        };
      })
      .addCase(getUserByUserId.pending, (state, action) => {
        return {
          ...state,
          userData: {
            loading: true,
          },
        };
      })
      .addCase(getUserByUserId.fulfilled, (state, action) => {
        return {
          ...state,
          userData: {
            loading: false,
            success: true,
            user: action?.payload,
          },
        };
      })
      .addCase(getUserByUserId.rejected, (state, action) => {
        return {
          ...state,
          userData: {
            loading: false,
            success: false,
            data: {},
            error: action?.payload,
          },
        };
      });
  },
});

export const { updateFilterValue } = userSlice.actions;
export default userSlice.reducer;
