import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToastConfig, successToastConfig } from "../../utils/helper";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const handleLogin = createAsyncThunk("/auth/handleLogin", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post(`/auth/login`, data);
        toast.success(response?.data?.message, successToastConfig);
        return response?.data;
    } catch (error) {
        toast.error(error?.response?.data?.message, errorToastConfig);
        return rejectWithValue(error.response.data);
    }
})


export const handleForgetPassword = createAsyncThunk("/auth/handleForgetPassword", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post(`/auth/forget-password`, data);
        toast.success(response?.data?.message, successToastConfig);
        return response?.data;
    } catch (error) {
        toast.error(error?.response?.data?.message, errorToastConfig);
        return rejectWithValue(error.response.data);
    }
})

export const handleResetPassword = createAsyncThunk("/auth/handleResetPassword", async (data, { rejectWithValue, dispatch }) => {
    try {
        const response = await axios.post(`/auth/reset-password/${data?.userId}/${data?.resetToken}`, data);
        toast.success(response?.data?.message, successToastConfig);
        return response?.data;
    } catch (error) {
        toast.error(error?.response?.data?.message, errorToastConfig);
        return rejectWithValue(error.response.data);
    }
})

