import { Box, styled } from "@mui/material";
import TopBar from "../components/TopBar";
import { useTheme } from "@emotion/react";
import { isTokenActive } from "../utils/functions";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, width }) => ({
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minHeight: `calc(100vh - ${250}px)`,
    flexGrow: 1,
    // padding: theme.breakpoints.down('sm') ? '16px' : '20px',
    // backgroundColor: COLORS.background,
    marginTop: 68,
    maxWidth: "100%",
    overflow: "hidden",
    width: "100%",
  })
);
const withUser =
  (RenderComponent, NavigateComponent) =>
  ({ to, replace, ...props }) => {
    const theme = useTheme();

    const token = localStorage.getItem('token')
    return (
      <>
        {isTokenActive(token) ?
          <Box sx={{ display: "flex" }}>
            <TopBar />

            <Main
              id="main-container"
              theme={theme}
              open={true}
              sx={{ padding: { xs: "7px", md: 3 } }}
            >
              <RenderComponent {...props} />
            </Main>
          </Box> : <NavigateComponent {...{ to, replace }}/>
        }
      </>
    );
  };

export default withUser;
