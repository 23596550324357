import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { successToastConfig } from "../../utils/helper";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const getUserByUserId = createAsyncThunk(
  "shop/getUserByUserId",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`/admin/get-user/${id}`);
      return response.data.result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUserById = createAsyncThunk(
  "user/updateUserById",
  async (data, { rejectWithValue, dispatch }) => {
    const { id, formValues } = data;
    try {
      const response = await axios.put(`/admin/user/${id}`, formValues);
      toast.success(response?.data?.message, successToastConfig);
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllUser = createAsyncThunk(
  "user/getAllUser",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `/admin/all-user${
          data?.status !== undefined ? `?status=${data?.status}` : ""
        }`
      );
      return response?.data?.result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
