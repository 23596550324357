import { useMemo } from "react";
import { PAGES } from "../utils/urls";
import Login from "../containers/auth/Login";
import UserList from "../containers/admin/user/UserList";

const useRoute = () => {
  const routes = useMemo(
    () => [
      {
        ...PAGES.AUTH.ROOT_LOGIN,
        element: <Login />,
        isAuth: true,
      },
      {
        ...PAGES.AUTH.LOGIN,
        element: <Login />,
        isAuth: true,
      },

      {
        ...PAGES.MANAGE_USER,
        element: <UserList />,
        isPrivate: true,
      },
    ],
    []
  );

  const authRoutes = useMemo(
    () => routes.filter((val) => val?.isAuth),
    [routes]
  );
  const privateRoutes = useMemo(
    () => routes.filter((val) => val?.isPrivate),
    [routes]
  );

  return {
    routes,
    authRoutes,
    privateRoutes,
  };
};

export default useRoute;
