import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { COLORS } from "../../../../utils/theme";
import { ICONS, statusOptions } from "../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUser,
  getUserByUserId,
  updateUserById,
} from "../../../../redux/actions/user";

const ModalTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.black,
  fontSize: "16px",
  fontWeight: "600",
  marginBottom: "1rem",
  display: "flex",
}));

const ModalContent = styled("div")(({ theme }) => ({
  color: COLORS.black,
  // fontSize: "16px",
  // fontWeight: "600",
  marginBottom: "1rem",
  marginLeft: "0.5rem",
}));

const ModalContentSelect = styled(Typography)(({ theme }) => ({
  color: COLORS.black,
  fontSize: "16px",
  fontWeight: "600",
  marginBottom: "1rem",
  marginLeft: "0.5rem",
}));

const CustomTextField = styled(TextField)`
  .MuiInputBase-root.MuiOutlinedInput-root {
    height: auto !important;
    border: 0.4px solid lightgrey;
  }
`;
const CustomDisableInput = styled(TextField)(() => ({
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "rgba(0, 0, 0, 0.38)",
    color: "rgba(0, 0, 0, 0.38)",
  },
}));

const EditUserDialog = ({ open, handleModalClose, title, users }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    status: "",
    comments: "",
  });
  const [formErrors, setFormErrors] = useState({
    status: "",
    comments: "",
  });

  const filterValue = useSelector((state) => state?.user?.filterValue);

  
  const validateField = (name, value, errors = {}) => {
    if (!value.trim()) {
      errors[name] = `${
        name.charAt(0).toUpperCase() + name.slice(1)
      } is required`;
    } else {
      errors[name] = "";
    }
    setFormErrors({ ...formErrors, ...errors });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    validateField(name, value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    Object.keys(formValues).forEach((name) => {
      validateField(name, formValues[name], errors);
    });
    setFormErrors(errors);

    const isValid = !Object.values(errors).some((error) => error !== "");
    if (isValid) {
      dispatch(
        updateUserById({ id: users?.userId, formValues: formValues })
      ).then(() => {
        dispatch(getAllUser({ status: filterValue }))
      });
      handleModalClose();
      setFormErrors({});
      setFormValues({});
    }
  };

  useEffect(() => {
    dispatch(getUserByUserId(users?.userId));
  }, [dispatch]);

  useEffect(() => {
    setFormValues({
      status: users?.status || "",
      comments: users?.comments || "",
    });
  }, [users]);

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          handleSubmit(event);
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleModalClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {ICONS.close}
      </IconButton>
      <DialogContent sx={{ padding: "0px 0px" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            background: "white",
            padding: "0.5rem 1.5rem",
          }}
        >
          <FormControl>
            <Typography variant="subtitle2">{"Shop Owner Name"}</Typography>
            <CustomDisableInput
              id="shop_owner"
              name="shop_owner"
              variant="outlined"
              placeholder="Enter Credentials"
              type="text"
              value={users?.shop_owner || "-"}
              disabled
              size="small"
            />
          </FormControl>
          <FormControl>
            <Typography variant="subtitle2" mt={1.5}>
              {"Email"}
            </Typography>
            <CustomDisableInput
              id="email"
              name="email"
              variant="outlined"
              placeholder="Email"
              type="email"
              value={users?.email || "-"}
              disabled
              size="small"
            />
          </FormControl>
          <FormControl>
            <Typography variant="subtitle2" mt={1.5}>
              {"Contact No."}
            </Typography>
            <CustomDisableInput
              id="phone"
              name="phone"
              type="text"
              fullWidth
              variant="outlined"
              value={users?.phone || "-"}
              disabled
            />
          </FormControl>
          <FormControl>
            <Typography variant="subtitle2" mt={1.5}>
              {"Country/City"}
            </Typography>
            <CustomDisableInput
              id="city"
              name="city"
              type="text"
              fullWidth
              variant="outlined"
              value={users?.city || "-"}
              disabled
            />
          </FormControl>

          <FormControl>
            <Typography variant="subtitle2" mt={1.5}>
              {"Select status"}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
              value={formValues.status || users?.status || ""}
              placeholder="Select status"
              autoFocus={formErrors.status !== "" ? false : true}
              inputProps={{
                name: "status",
                id: "status",
              }}
              error={formErrors.status}
              onChange={handleChange}
            >
              {statusOptions.map((status, index) => (
                <MenuItem key={index} value={status.label}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!formErrors.status}>
              {formErrors.status}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Typography variant="subtitle2" mt={1.5}>
              {"Comment"}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <CustomTextField
              fullWidth
              id="comments"
              name="comments"
              multiline
              rows={3}
              variant="outlined"
              size="small"
              value={formValues.comments || users?.comments}
              onChange={handleChange}
            />
            <FormHelperText error={!!formErrors.comments}>
              {formErrors.comments}
            </FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
