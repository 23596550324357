import { createSlice } from "@reduxjs/toolkit";
import { handleForgetPassword, handleLogin } from "../actions/auth";

const initialState = {
  auth: {
    success: false,
    loading: false,
    token: null,
    error: {}
  },
  token: null,
  isLogged: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleLogout: state => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogin.pending, (state, action) => {
      return {
        ...state,
        auth: {
          loading: true,
        },
      }
    }).addCase(handleLogin.fulfilled, (state, action) => {
      return {
        ...state,
        auth: {
          success: true,
          loading: false,
          token: action.payload,
          error: {}
        },
      }
    }).addCase(handleLogin.rejected, (state, action) => {
      return {
        ...state,
        auth: {
          success: false,
          loading: false,
          token: null,
          error: action.payload
        },
      }
    })
      .addCase(handleForgetPassword.pending, (state, action) => {
        return {
          ...state,
          auth: {
            loading: true,
          },
        }
      }).addCase(handleForgetPassword.fulfilled, (state, action) => {
        return {
          ...state,
          auth: {
            success: true,
            loading: false,
            token: action.payload,
            error: {}
          },
        }
      }).addCase(handleForgetPassword.rejected, (state, action) => {
        return {
          ...state,
          auth: {
            success: false,
            loading: false,
            token: null,
            error: action.payload
          },
        }
      })
  }
});

export const { handleLogout } = authSlice.actions;

export default authSlice.reducer;