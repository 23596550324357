import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../utils/theme";

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: "#12709e",
  fontSize: "34px",
  fontWeight: "300",
  fontFamily: "Comfortaa !important",
}));

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          bgcolor: COLORS.primary.light, // theme.palette.background.default,
          borderBottom: `1px solid #e6ebf1`,
          overflow: "hidden",
          boxShadow: "0 1px 4px -1px rgb(0 0 0 / 15%)",
        }}
      >
        <Toolbar></Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs" sx={{ paddingTop: "5%" }}>
        <Box
          sx={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <StyledHeading component="h1" variant="h5">
            Page not found
          </StyledHeading>

          <Button
            type="submit"
            onClick={() => navigate("/users")}
            variant="contained"
            color="inherit"
            sx={{
              minWidth: "100px",
              backgroundColor: COLORS.primary.light,
              margin: 4,
            }}
          >
            Go back to main page
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound;
