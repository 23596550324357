
import React from 'react'
import { Link } from 'react-router-dom';
import { TableRow } from '@mui/material';


const ClickableRow = ({ id, to, handlePriceDetails, children }) => {
    return <> {to ? (
        <TableRow component={Link} to={to} id={id} onClick={() => handlePriceDetails(id)} hover role="checkbox" tabIndex={-1} sx={{ textDecoration: 'none' }}>
            {children}
        </TableRow>
    ) : (
        <TableRow hover role="checkbox" id={id} tabIndex={-1}>
            {children}
        </TableRow>
    )}
    </>
}

export default ClickableRow