import moment from "moment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PaymentIcon from "@mui/icons-material/Payment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const successToastConfig = {
  position: "bottom-center",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const errorToastConfig = {
  position: "bottom-center",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const ICONS = {
  Users: <PeopleAltIcon />,
  Dashboard: <SpaceDashboardIcon />,
  Payment: <PaymentIcon />,
  edit: <EditIcon />,
  delete: <DeleteIcon />,
  close: <CloseIcon />,
  actions: <MoreVertIcon/>,
};

export const userColumns = [
  { id: "shop_owner", label: "Shop Owner Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 120 },
  { id: "phone", label: "Contact No.", minWidth: 100 },
  { id: "city", label: "Country/City", minWidth: 120 },
  { id: "status", label: "Status", minWidth: 70 },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    align: "center",
  },
];

export const SetColValue = (item, columnKey, dateFormat) => {
  let value;
  if (columnKey === "email") {
    value = item?.email;
  } else if (columnKey === "unit_amount") {
    value = `${item[columnKey] / 100} ${item.currency.toUpperCase()} / ${item?.recurring?.interval
      }`;
  } else if (columnKey === "createdAt" || columnKey === "updatedAt") {
    const parsedDate = moment(item[columnKey]);
    value = parsedDate.format(dateFormat);
  } else if (columnKey === "created" || columnKey === "updated") {
    value = moment.unix(item[columnKey]).format("YYYY-MM-DD HH:mm:ss");
  } else {
    value = item[columnKey] === null ? "-" : item[columnKey];
  }
  return value;
};

export const statusOptions = [
  { id: "active", label: "active" },
  { id: "inactive", label: "inactive" },
  { id: "lost", label: "lost" },
  { id: "won", label: "won" },
];

export const concat = (...fields) => fields.filter(Boolean).join("");