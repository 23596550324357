import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { ICONS } from "../../utils/helper";
import { COLORS } from "../../utils/theme";
import { useTheme } from "@emotion/react";

const ModalTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.black,
  fontSize: "16px",
  fontWeight: "600",
  marginBottom: "1rem",
  display: "flex",
}));
const ModalContent = styled(Typography)(({ theme }) => ({
  color: COLORS.black,
  fontSize: "16px",
  fontWeight: "300",
  marginBottom: "1rem",
  marginLeft: "0.5rem",
}));
const DialogMainContent = styled(DialogContent)(({ theme }) => ({
  padding: "16px 16px",
}));

const ShopDialog = ({ open, handleModalClose, title, users }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleModalClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleModalClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {ICONS.close}
      </IconButton>
      <DialogMainContent dividers>
        <DialogContentText>
          <ModalTitle>
            Shop Name: <ModalContent>{users?.shopName}</ModalContent>
          </ModalTitle>
          <ModalTitle>
            Shop URL: <ModalContent>{users?.domain}</ModalContent>
          </ModalTitle>
          <ModalTitle>
            Subscription Plan:{" "}
            <ModalContent>{users?.subscriptionName}</ModalContent>
          </ModalTitle>
        </DialogContentText>
      </DialogMainContent>
      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShopDialog;
