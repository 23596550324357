import styled from "@emotion/styled";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { COLORS } from "../../utils/theme";
import { ICONS, SetColValue, statusOptions } from "../../utils/helper";
import ClickableRow from "../ClickableRow";
import { useTheme } from "@emotion/react";
import ShopDialog from "../ShopDialog";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser, getUserByUserId } from "../../redux/actions/user";
import MenuItems from "./MenuItems";
import EditUserDialog from "../../containers/admin/user/UserList/EditUserDialog";
import NoDataFound from "../NoDataFound";
import { updateFilterValue } from "../../redux/reducers/userSlice";

const TableHeader = styled(Typography)(({ theme }) => ({
  color: COLORS.primary.light,
  fontSize: "28px",
  fontWeight: "300",
}));

const FilterTitle = styled(Typography)(({ theme }) => ({
  color: COLORS.primary.light,
  fontSize: "14px",
  fontWeight: "600",
}));

const CustomSelect = styled(Select)`
  & .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    border: 1px solid #30aed0;
  }
`;

const CommanTable = ({
  title,
  rows,
  columns,
  open,
  anchorEl,
  setAnchorEl,
  handleViewUserData,
  handleClose,
  shopData,
  setShopData,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openModal, setOpenModal] = React.useState(false);

  const [menuOpen, setOpenMenu] = useState(null);

  const usersById = useSelector((state) => state?.user?.userData?.user);
  const userDataUpdate = useSelector(
    (state) => state?.user?.userDataUpdate?.user
  );

  const dispatch = useDispatch();
  const theme = useTheme();
  const users = useSelector((state) => state?.user?.user?.data);
  const filterValue = useSelector((state) => state?.user?.filterValue);

  const handleModalOpen = () => {
    setOpenModal(true);
    setAnchorEl(null);
  };
  const handleModalClose = () => {
    setOpenModal(false);
    setAnchorEl(null);
    setOpenMenu(null);
    setShopData({});
  };

  const handleEditModalOpen = (e) => {
    setOpenMenu(e.currentTarget);
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleFilterStatusChange = (event) => {
    dispatch(updateFilterValue(event.target.value))
    dispatch(getAllUser({ status: event.target.value }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (Object.keys(shopData).length !== 0) {
      dispatch(getUserByUserId(shopData));
    }
  }, [dispatch, shopData]);

  useEffect(() => {
    dispatch(getAllUser({status: filterValue}));
  }, [dispatch]);

  const sortedUserData = useMemo(() => {
    return (
      users &&
      [...users].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    );
  }, [users]);

  return (
    <Box>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <TableHeader>{title}</TableHeader>
        <Box sx={{ minWidth: 130, marginBottom: "10px" }}>
          <FilterTitle>Filter by status</FilterTitle>
          <FormControl fullWidth>
            <CustomSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              autoFocus
              value={filterValue}
              onChange={handleFilterStatusChange}
            >
              {statusOptions.map((status, index) => (
                <MenuItem key={index} value={status.label}>
                  {status.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </Box>
      </Box>
      {sortedUserData?.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 700 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      key={column?.id}
                      align={column?.align}
                      style={{ minWidth: column?.minWidth }}
                    >
                      {column?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUserData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => {
                    return (
                      <ClickableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.id}
                        id={item._id}
                      >
                        {columns?.map((column) => {
                          if (column.id === "actions") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? "long-menu" : undefined}
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={(e) => handleViewUserData(e, item)}
                                >
                                  {ICONS.actions}
                                </IconButton>
                                <MenuItems
                                  item={item}
                                  anchorEl={anchorEl}
                                  open={open}
                                  handleClose={handleClose}
                                  handleModalOpen={handleModalOpen}
                                  handleEditModalOpen={handleEditModalOpen}
                                />
                              </TableCell>
                            );
                          }
                          const value = SetColValue(
                            item,
                            column.id,
                            "DD-MM-YYYY, h:mm:ss a"
                          );
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </ClickableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={sortedUserData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {openModal && (
            <ShopDialog
              open={openModal}
              handleModalClose={handleModalClose}
              title="Shop Data"
              users={usersById}
            />
          )}
          {menuOpen && (
            <EditUserDialog
              open={menuOpen}
              handleModalClose={handleModalClose}
              title="Edit User"
              users={usersById}
            />
          )}
        </Paper>
      ) : (
        <NoDataFound />
      )}
    </Box>
  );
};

export default CommanTable;
