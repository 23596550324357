import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "../../../../redux/actions/user";
import CommanTable from "../../../../components/CommanTable";
import { userColumns } from "../../../../utils/helper";

export default function UserList() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.user?.user?.data);
  const filterValue = useSelector((state) => state?.user?.filterValue);

  const [anchorEl, setAnchorEl] = useState(null);
  const [shopData, setShopData] = useState({});
  const open = Boolean(anchorEl);
  const handleViewUserData = (e , shopData) => {
    setAnchorEl(e.currentTarget);
    setShopData(shopData?.userId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getAllUser({status: filterValue}));
  }, [dispatch]);

  return (
    <CommanTable
      title="Users"
      rows={users}
      columns={userColumns}
      open={open}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      shopData={shopData}
      setShopData={setShopData}
      handleClose={handleClose}
      handleViewUserData={handleViewUserData}
    />
  );
}
