import React from "react";
import { Card, CardContent, Paper, Typography, styled } from "@mui/material";

const StyledHeading = styled(Typography)(({ theme }) => ({
  color: "#30aed0",
  fontSize: "34px",
  fontWeight: "300",
  fontFamily: "Comfortaa !important",
  display: "flex",
  justifyContent: "center",
}));

const CardContentMain = styled(CardContent)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const NoDataFound = () => {
  return (
    <div
      style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
    >
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Card>
          <CardContentMain>
            <img
              height={250}
              src={"assets/images/no_data.gif"}
              alt="No Data Found"
            />
          </CardContentMain>
          <CardContent>
            {" "}
            <StyledHeading>No Data Found</StyledHeading>
          </CardContent>
        </Card>
      </Paper>
    </div>
  );
};

export default NoDataFound;
