import React from "react";
import { Route, Routes } from "react-router-dom";
import useRoute from "../hooks/useRoute";
import AuthLayout from "../layout/AuthLayout";
import NotFound from "../components/NotFound";
import PrivateLayout from "../layout/PrivateLayout"

const Routing = ({ ...props }) => {
  const { authRoutes, privateRoutes } = useRoute();

  return (
    <Routes {...props}>
      <Route path="/" element={<AuthLayout />}>
        {authRoutes?.map(({ id, ...data }) => (
          <Route index key={id} {...data} />
        ))}
      </Route>
      <Route path="/" element={<PrivateLayout />}>
        {privateRoutes?.map(({ id, ...data }) => (
          <Route index key={id} {...data} />
        ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routing;
